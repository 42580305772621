<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular :size="70" :width="7" color="green" indeterminate></v-progress-circular></v-overlay>
    <!-- Modal Components::Begin -->
    <NewRMRequisition :dialog.sync="editDialog" :data="blendPlan" @onSave="onSave" />

    <v-container fluid class="px-6 py-3">
      <v-row>
        <!-- #region Card section 1 -->
        <v-col cols="12" :class="{ 'mb-5': currentPage.permissions.length <= 0 }">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <!-- #region Plan Name -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">RM Requisition</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.name }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Date -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Production Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ formatDate(this.blendPlan.productionDate) }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Quantity -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Quantity</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.productionQuantity }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Blend Code Name -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Blend Code Name</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.blendCodeName }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Source Number -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Source Number</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.sourceNumber }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Warehouse -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Warehouse</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.companyWarehouseName }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <v-col>
                  <v-btn @click="editDialog = true" dark class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow">
                    Edit
                  </v-btn>
                </v-col>
                <!-- <v-col v-show="this.planGenerationStatus">
                  <action-button :actions="currentPage.actions_allowed" @onClick="onActionClick" />
                </v-col> -->
                <!-- #endregion -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- #endregion -->

        <!-- #region Card section 2 -->
        <!--  -->
        <v-col cols="12" v-if="currentPage.permissions.length > 0">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text>
              <v-form ref="frm">
                <v-card-text class="px-0 py-0">
                  <v-row class="pa-2">
                    <!-- #region PO Stock Selection -->
                    <!-- <v-col class="shrink">
                      <label class="text-md text-typo ms-1" style="white-space: nowrap">Use PO Stock</label>
                      <v-row class="mt-5 align-center ms-1">
                        <v-switch
                          :ripple="false"
                          class="mt-0 pt-0 ms-3 switch"
                          v-model="selectedData.poStockSwitch"
                          hide-details
                          color="#49ab5cf2"
                          inset
                          :label="POStockSelection"
                        ></v-switch>
                      </v-row>
                    </v-col> -->
                    <!-- #endregion -->
                    <!-- #region Company warehouse selector -->
                    <!-- <v-col cols="3">
                      <label class="text-md text-typo ms-1">Select other warehouses</label>
                      <v-select
                        v-model="selectedData.companyWarehouses"
                        :items="filteredCompanyWarehouses"
                        item-text="name"
                        item-value="id"
                        dense
                        flat
                        filled
                        solo
                        class="input-style font-size-input text-light-input placeholder-light select-style mt-2"
                        outlined
                        chips
                        multiple
                        single-line
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0" label color="bg-default" class="py-1 px-2 my-0">
                            <span class="text-white text-caption ls-0">{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text text-caption">
                            (+{{ companyWarehouses.length - 1 }}
                            others)
                          </span>
                        </template>
                        <template v-slot:prepend-item>
                          <v-list-item ripple @mousedown.prevent @click="toggle">
                            <v-list-item-action>
                              <v-icon :color="companyWarehouses.length > 0 ? 'indigo darken-4' : ''">
                                {{ icon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Select All </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-col> -->
                    <!-- #endregion -->
                    <!-- #region Search Button -->
                    <!-- <v-col class="d-flex align-center shrink">
                      <v-btn class="bg-success py-5 px-0 font-weight-bold text-capitalize btn-primary bg-success" @click="searchData">
                        <v-icon size="20" class="text-white"> fas fa-search </v-icon>
                      </v-btn>
                    </v-col> -->
                    <!-- #endregion -->
                    <!-- </v-row> -->

                    <!-- <v-row> -->
                    <!-- #region Autogenerate Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="availableStock.length > 0 && !isPlanSaved"
                        @click="generatePlan"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-3 px-6"
                        >Auto Generate Plan</v-btn
                      >
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Rearrange Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        @click="showSelected()"
                        dark
                        class="font-weight-bold text-capitalize btn-secondary bg-secondary py-5 px-5 shadow"
                      >
                        <!-- <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon> -->
                        Show Selected
                      </v-btn>
                    </v-col>
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow"
                        @click="reAssignPlan()"
                      >
                        <v-icon size="20" class="text-white mr-1"> fas fa-retweet </v-icon>
                        Re-Arrange
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Save Button -->
                    <!--     v-show="
                          selectedData.selectedStocks.length > 0 && isRearranged
                        " -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        @click="saveDetails"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-3 px-6"
                        >Save</v-btn
                      >
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Download Button -->
                    <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        @click="downloadPlan()"
                        dark
                        class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow"
                      >
                        <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon>
                        Download
                      </v-btn>
                    </v-col>
                    <!-- #endregion -->
                    <!-- #region Delete Button -->
                    <!-- <v-col class="d-flex align-center shrink">
                      <v-btn
                        v-show="isPlanSaved"
                        @click="deletePlan"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-danger
                          bg-danger
                          py-3
                          px-6
                        "
                        >Delete</v-btn
                      >
                    </v-col> -->
                    <!-- #endregion -->
                    <!-- #region Generate Provision Plan Button -->
                    <v-col class="d-flex align-center shrink">
                      <!-- <v-btn
                       
                        @click="approvePlan()"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-primary
                          bg-success
                          py-5
                          px-5
                          shadow
                        "
                      >
                        <v-icon size="20" class="text-white mr-1">
                          fas fa-cloud-download-alt
                        </v-icon>
                        Confirm Plan
                      </v-btn> -->
                      <action-button v-show="isPlanSaved" :actions="currentPage.actions_allowed" @onClick="allowedAction" />
                    </v-col>
                    <!-- #endregion -->
                  </v-row>
                </v-card-text>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- #endregion -->

        <!-- #region Table Section -->
        <!-- -->
        <v-col cols="12" v-if="currentPage.permissions.length > 0">
          <v-card class="card-shadow border-radius-xl">
            <ag-grid-vue
              rowHeight="40"
              style="height: 750px"
              class="ag-theme-alpine"
              @grid-ready="onGridReady"
              :rowData="availableStock"
              :columnDefs="headers"
              :defaultColDef="defaultColDef"
              rowSelection="multiple"
              :rowMultiSelectWithClick="true"
              @first-data-rendered="onFirstDataRendered"
            >
            </ag-grid-vue>
            <!--               :tabToNextCell="tabToNextCell" -->

            <!-- <v-data-table
              :headers="headers"
              :items="availableStock"
              item-key="id"
              mobile-breakpoint="0"
              fixed-header
              class="table"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
              show-select
              :item-class="itemRowBackground"
              v-model="selectedData.selectedStocks"
              @item-selected="selectionEvent"
            >
              <template v-slot:[`item.gpDate`]="{ item }">
                <span>{{ formatDate(item.gpDate) }}</span>
              </template>
              <template v-slot:[`item.inventoryId`]="{ item }">
                <span>{{ checkStockType(item.inventoryId) }}</span>
              </template>
              <template v-slot:[`item.qtyAction`]="{ item, index }">
                <v-text-field
                  :disabled="currentPage.permissions.length <= 0"
                  v-model.number="item.requiredQuantity"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    qtyText
                    shrink
                    ml-2
                  "
                  full-width
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Quantity"
                  persistent-hint
                  @mousedown.stop
                  @touchstart.stop
                  @input="requiredQtyCalc(item, index)"
                ></v-text-field>
              </template>
            </v-data-table> -->
          </v-card>
        </v-col>
        <!-- <v-card class="card-shadow border-radius-xl">
          <v-card-text class="card-padding" id="scroll_1">
           
          </v-card-text>
        </v-card> -->

        <!-- #endregion -->
      </v-row>
    </v-container>
    <!-- Selected List -->
    <v-dialog v-model="showSelectedList" persistent fullscreen transition="dialog-bottom-transition">
      <v-card class="card-shadow border-radius-xl">
        <v-card-title>
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col cols="10" class="text-wrap">
              <div class="grid-container">
                <!-- <div
                  class="grid-item"
                  v-for="item in calculatedBlendQtyDetails"
                  :key="item.itemName"
                >
                  {{ item.itemName }} :
                  {{ item.requiredQty - item.selectedQty }}
                </div> -->
              </div>
            </v-col>
            <v-col align="right" justify="center">
              <v-btn
                @click="closeListDialogue"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mb-5 mt-5 mr-3"
                >Go Back to Select More
              </v-btn>

              <v-btn
                @click="saveDetails"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="font-weight-bold text-capitalize btn-ls btn-primary bg-success py-3 px-6 mb-5 mt-5"
                >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-col>
                <v-radio-group v-model="enter_field" row @change="changeEnterField()">
                  <v-radio label="Enter by Weight" color="red" value="weight"></v-radio>
                  <v-radio label="Enter by bags" color="red" value="bags"></v-radio>
                </v-radio-group>
              </v-col>
            </v-col>
          </v-row>
          <ag-grid-vue
            rowHeight="40"
            style="height: 500px"
            class="ag-theme-alpine"
            @grid-ready="onGridReady1"
            :rowData="selectedData.selectedStocks"
            :columnDefs="headers1"
            :defaultColDef="defaultColDef"
            :getRowId="getRowId"
          >
          </ag-grid-vue>
        </v-card-text> </v-card
    ></v-dialog>
    <!-- End -->
  </div>
</template>

<script>
import ActionButton from "../../../../components/ActionButton.vue";
import CompanyWarehouseService from "../../../master/CompanyWareHouse/api";
import RequisitionAPI from "../../Requisition/api";
import api from "../api";
import { TABLE_HEADERS_AG, TABLE_HEADERS_AG2 } from "../constants";
import NewRMRequisition from "../../Requisition/components/NewRMRequisition.vue";
import { AgGridVue } from "ag-grid-vue";
export default {
  name: "ProvisionalPlanDetails",
  components: {
    ActionButton,
    NewRMRequisition,
    AgGridVue,
  },
  data() {
    return {
      editDialog: false,
      blendPlan: null,
      blendPlanDetails: [],
      companyWarehouses: [],
      selectedData: {
        companyWarehouses: [],
        selectedStocks: [],
        poStockSwitch: false,
      },
      availableStock: [],
      overlay: false,
      requiredQuantity: 0,
      isPlanSaved: false,
      currentPage: {},
      headers: TABLE_HEADERS_AG,
      headers1: TABLE_HEADERS_AG2,
      isRearranged: false,
      showSelectedList: false,
      //AG GRID
      enter_field: "bags",
      getRowId: null,
      gridApi: null,
      gridColumnApi: null,

      getRowId1: null,
      gridApi1: null,
      gridColumnApi1: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        sortable: true,
        suppressMovable: true,
        resizable: true,
        filterParams: { suppressAndOrCondition: true },
      },
    };
  },
  computed: {
    icon() {
      if (this.selectAll) return "ni-fat-remove text-lg";
      if (this.selectSome) return "ni-fat-delete";
      return "fa-square-o";
    },
    selectAll() {
      return this.selectedData.companyWarehouses.length === this.companyWarehouses.length;
    },
    selectSome() {
      return this.companyWarehouses.length > 0 && !this.selectAll;
    },
    filteredCompanyWarehouses() {
      return this.companyWarehouses.filter((x) => x.id !== this.blendPlan?.companyWarehouseId);
    },
    POStockSelection() {
      return this.selectedData.poStockSwitch ? "Yes" : "No";
    },
  },
  watch: {
    blendPlan(val) {
      if (!val) {
        this.$router.go(-1);
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAll) {
          this.selectedData.companyWarehouses = [];
        } else {
          // TODO Rewrite select all item logic
          //   this.selectedData.companyWarehouses = this.companyWarehouses.slice();

          //   const data = Object.values(
          //     data.reduce((a, b) => {
          //       console.log(a);
          //     })
          //   );

          this.selectedData.companyWarehouses = this.filteredCompanyWarehouses;
        }
      });
    },
    onSave(data) {
      this.blendPlan = data;
      this.editDialog = false;
      this.goBack();
    },
    checkCardColor(item) {
      let _item;
      // Checking if item is from Inventory Stock or PO Stock
      if (item.inventoryId) {
        _item = this.selectedData.selectedStocks.find((x) => x.inventoryId == item.inventoryId);
      } else {
        _item = this.selectedData.selectedStocks.find((x) => x.purchaseChildId == item.purchaseChildId);
      }

      if (_item) {
        if (item.totalNetKgs < _item.requiredQuantity) {
          return "rgba(240, 89, 89, 0.2)";
        } else {
          return "rgba(129, 242, 116, 0.2)";
        }
      } else {
        return "white";
      }
    },
    itemRowBackground: function (item) {
      return "style-1";
    },
    // itemRowBackGround(item) {
    //   let _item;

    //   // Checking if item is from Inventory Stock or PO Stock
    //   if (item.inventoryId) {
    //     _item = this.selectedData.selectedStocks.find((x) => x.inventoryId == item.inventoryId);
    //   } else {
    //     _item = this.selectedData.selectedStocks.find((x) => x.purchaseChildId == item.purchaseChildId);
    //   }

    //   if (_item) {
    //     if (item.totalNetKgs < _item.requiredQuantity) {
    //       return "style-3";
    //     } else {
    //       return "style-2";
    //     }
    //   } else {
    //     return "style-1";
    //   }
    // },
    isCardSelected(item) {
      const Index = this.selectedData.selectedStocks.indexOf(item);

      if (Index > -1) {
        return false;
      } else {
        return true;
      }
    },
    checkStockType(item) {
      if (item) {
        return "Inventory";
      } else {
        return "PO";
      }
    },
    checkStockLevel(item) {
      const _item = this.availableStock.find((x) => x.inventoryId == item.inventoryId);

      if (_item) {
        return item.totalNetKgs === _item.totalNetKgs ? "orange" : "green";
      }
    },
    qtyBoxValidation(params) {
      let data = params.data;

      console.log("DATA", data);

      if (parseFloat(data.requiredQuantity) > parseFloat(data.totalNetKgs) && data.requiredQuantity !== "") {
        return false;
      }

      return true;
    },
    requiredQtyCalc(event, field) {
      if (field == "weight") {
        // Setting Data to UI
        let rowNode = this.gridApi1.getRowNode(event.data.id);
        let rowData = rowNode.data;
        const _noPackages = Math.ceil(parseFloat(rowData.requiredQuantity) / parseFloat(rowData.netKgs));
        rowData.requiredQuantity = parseFloat(rowData.requiredQuantity);
        rowData.requiredPackages = _noPackages;
        rowNode.setData({ ...rowData });
        let _index = this.selectedData.selectedStocks.findIndex((el) => el.id == rowData.id);
        // Setting Data to Variable
        this.selectedData.selectedStocks[_index]["requiredQuantity"] = rowData.requiredQuantity;
        this.selectedData.selectedStocks[_index]["requiredPackages"] = _noPackages;
        this.selectedData.selectedStocks[_index].validate = this.qtyBoxValidation(rowNode);
        this.showBlendQtyDetails();
      }
      if (field == "bags") {
        // Setting Data to UI
        let rowNode = this.gridApi1.getRowNode(event.data.id);
        let rowData = rowNode.data;
        const _requiredQuantity = Math.ceil(parseFloat(rowData.netKgs) * parseFloat(rowData.requiredPackages));
        rowData.requiredPackages = parseFloat(rowData.requiredPackages);
        rowData.requiredQuantity = _requiredQuantity;
        rowNode.setData({ ...rowData });
        let _index = this.selectedData.selectedStocks.findIndex((el) => el.id == rowData.id);
        // Setting Data to Variable
        this.selectedData.selectedStocks[_index]["requiredQuantity"] = _requiredQuantity;
        this.selectedData.selectedStocks[_index]["requiredPackages"] = rowData.requiredPackages;
        this.selectedData.selectedStocks[_index].validate = this.qtyBoxValidation(rowNode);
        this.showBlendQtyDetails();
      }
    },
    showSelected() {
      this.selectedData.selectedStocks = this.gridApi.getSelectedRows();

      if (this.selectedData.selectedStocks.length > 0) {
        this.showSelectedList = true;
        this.saveList = this.selectedData.selectedStocks;
      } else {
        this.showWarning("Select Atleast one row...");
      }
    },
    async searchData() {
      if (this.selectedData.selectedStocks.length > 0) {
        this.showWarningAlert();
        return;
      }
      this.overlay = true;
      const _data = {
        blendProductionPlanMasterId: this.blendPlan.id,
        usePOStock: this.selectedData.poStockSwitch,
        warehouseIds: this.selectedData.companyWarehouses,
      };

      try {
        this.availableStock = await RequisitionAPI.getAvailableStockForProduction(_data);
        this.overlay = false;
        // console.log(" this.availableStock", this.availableStock);
        // this.availableStock.forEach((el) => {
        //   console.log("el.requiredQuantity", el.requiredQuantity);
        // });
      } catch (error) {
        this.showErrorAlert(error);
      }
      this.selectedData.selectedStocks = [];
      this.overlay = false;
    },
    async generatePlan() {
      this.overlay = true;
      const _data = {
        blendProductionPlanMasterId: this.blendPlan.id,
        usePOStock: true,
        warehouseIds: this.selectedData.companyWarehouses,
      };
      try {
        this.blendPlanDetails = await api.generateBlendPlan(_data);
        this.mergeDetailsWithStock();
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    closeListDialogue() {
      this.showSelectedList = false;
    },
    async saveDetails() {
      let validationFail = this.selectedData.selectedStocks.some((el) => el.validate == false);
      if (validationFail) {
        this.showErrorAlert("Please Check the Details.");
      } else {
        this.overlay = true;
        try {
          const result = await api.saveBlendPlanDetail(this.blendPlan.id, this.selectedData.selectedStocks);
          this.isPlanSaved = true;
          this.overlay = false;
          this.showSuccessAlert("Plan Saved Successfully");
        } catch (error) {
          this.showErrorAlert(error);
          console.log(error);
          // alert(error);
          this.overlay = false;
        } finally {
          this.closeListDialogue();
        }
      }
    },
    async downloadPlan() {
      await api.downloadProvisionalPlan(this.blendPlan.id, this.blendPlan.name);
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    checkIfTwoWarehousesIncluded() {
      const _wareHouses = [];
      const isPoStockIncluded = false;
      this.blendPlanDetails.forEach((item) => {
        _wareHouses.push(item.companyWarehouseId);
        if (!item.inventoryId) {
          isPoStockIncluded = true;
        }
      });
      this.selectedData.companyWarehouses = [...new Set(_wareHouses.filter((x) => x !== this.blendPlan.companyWarehouseId))];
      this.selectedData.poStockSwitch = isPoStockIncluded;
    },
    onFirstDataRendered(params) {
      params.api.forEachNode((node) => node.setSelected(node.data.isSelected == true));
    },
    mergeDetailsWithStock() {
      this.selectedData.selectedStocks = [];
      this.blendPlanDetails.forEach((item) => {
        // Checking if item is from Inventory Stock or PO Stock
        if (!item.inventoryId) {
          const Index = this.availableStock.findIndex((x) => x.purchaseChildId === item.purchaseChildId);

          this.availableStock[Index]["requiredQuantity"] = item.totalNetKgs;
          this.availableStock[Index]["requiredPackages"] = item.noOfPackages;
          this.availableStock[Index].isSelected = true;
          const _item = this.availableStock.find((x) => x.purchaseChildId === item.purchaseChildId);
          this.selectedData.selectedStocks.push(_item);
        } else {
          const Index = this.availableStock.findIndex((x) => x.inventoryId === item.inventoryId);

          this.availableStock[Index]["requiredQuantity"] = item.totalNetKgs;
          this.availableStock[Index]["requiredPackages"] = item.noOfPackages;
          this.availableStock[Index].isSelected = true;
          const _item = this.availableStock.find((x) => x.inventoryId === item.inventoryId);
          this.selectedData.selectedStocks.push(_item);
        }
      });
      let selectedData = this.selectedData.selectedStocks;
      this.gridApi.forEachNode(function (node) {
        let indx = selectedData.findIndex((el) => el.inventoryId == node.data.inventoryId);
        if (indx > -1) {
          node.setSelected(true);
        }
      });
    },
    selectionEvent(object) {
      if (!object.value) {
        const _item = this.availableStock.find((x) => x.inventoryId == object.item.inventoryId);
        _item.requiredPackages = "";
        _item.requiredQuantity = "";
      }
    },
    removeEmptyStock() {
      this.selectedData.selectedStocks.forEach((e, index) => {
        if (e.totalNetKgs === 0) {
          console.log(e);
          this.selectedData.selectedStocks.splice(index, 1);
          const Index = this.availableStock.findIndex((x) => x.inventoryId === e.inventoryId);
          console.log(Index);

          if (Index == -1) return;

          this.availableStock[Index]["requiredPackages"] = "";
          this.availableStock[Index]["requiredQuantity"] = "";
        }
      });
    },
    showWarningAlert() {
      this.$swal({
        title: "Are you sure?",
        text: "Your selected data will be cleared!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "bg-success",
          cancelButton: "btn-primary bg-danger",
        },
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          // TODO Make it better later
          this.overlay = true;
          const _data = {
            blendProductionPlanMasterId: this.blendPlan.id,
            usePOStock: this.selectedData.poStockSwitch,
            warehouseIds: this.selectedData.companyWarehouses,
          };

          this.availableStock = await api.getAvailableStockForProduction(_data);
          this.selectedData.selectedStocks = [];
          this.overlay = false;
          // continue;
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          return;
        }
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 5000,
        icon: "error",
      });
      this.overlay = false;
    },
    deletePlan() {
      console.log("hi");
    },
    onActionClick(item) {
      if (item === "APPROVE") {
        this.approveBlendPlan();
      } else if (item === "CANCEL") {
        this.cancelBlendPlan();
      }
    },
    async allowedAction() {
      try {
        await api.approveBlendPlan(this.blendPlan.id);
        this.showSuccessAlert("Provisional Plan Has been Approved");
        this.$router.go(-1);
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async cancelBlendPlan() {
      try {
        await api.cancelBlendPlan(this.blendPlan.id);
        this.showSuccessAlert("Plan Canceled Successfully");
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async reAssignPlan() {
      this.overlay = true;
      this.isRearranged = true;
      try {
        this.blendPlanDetails = await RequisitionAPI.reArrangeBlendPlan(this.blendPlan.id);
        this.removeEmptyStock();
        this.mergeDetailsWithStock();
        this.checkIfTwoWarehousesIncluded();
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    //
    // Grid Create
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onGridReady1(params) {
      // Adding Editable field
      if (this.headers1.length == 11) {
        this.headers1[0].headerCheckboxSelection = false;
        this.headers1[0].checkboxSelection = false;
        this.headers1.push({
          headerName: "Required Quantity",
          field: "requiredQuantity",

          cellStyle: (params) => {
            if (this.qtyBoxValidation(params)) {
              return { backgroundColor: "white", color: "black" };
            }
            return { backgroundColor: "pink", color: "black" };
          },
          width: 100,
        });

        this.headers1.push({
          headerName: "Selected No of Packages",
          field: "requiredPackages",

          width: 100,
          cellStyle: (params) => {
            if (this.qtyBoxValidation(params)) {
              return { backgroundColor: "white", color: "black" };
            }
            return { backgroundColor: "pink", color: "black" };
          },
        });

        this.headers1.push({
          headerName: "Batch No",
          field: "batchNo",
          class: "text-secondary font-weight-bolder opacity-7",
          align: "center",
          width: 100,
          sortable: true,
        });
      }
      // Setting Editable field by weight or bags
      if (this.enter_field == "weight") {
        this.headers1[11].editable = true;
        this.headers1[11].onCellValueChanged = async (event) => {
          await this.requiredQtyCalc(event, "weight");
        };
      }
      if (this.enter_field == "bags") {
        this.headers1[12].editable = true;
        this.headers1[12].onCellValueChanged = async (event) => {
          await this.requiredQtyCalc(event, "bags");
        };
      }
      this.gridApi1 = params.api;
      this.gridColumnApi1 = params.columnApi;
    },
    changeEnterField() {
      let vueInstance = this;
      var columnDefs = this.gridApi1.getColumnDefs();

      let idx = 0;
      // Setting Editable field by weight or bags
      if (this.enter_field == "weight") {
        idx = 11;
      }
      if (this.enter_field == "bags") {
        idx = 12;
      }
      columnDefs.forEach(function (colDef, index) {
        if (index == idx) {
          colDef.editable = true;
          colDef.onCellValueChanged = async function (event) {
            await vueInstance.requiredQtyCalc(event, vueInstance.enter_field);
          };
        } else {
          colDef.editable = false;
          // colDef.onCellValueChanged = null;
        }
      });
      this.headers1 = columnDefs;
    },
  },
  created() {
    this.getRowId = (params) => {
      return params.data.id;
    };
  },
  async mounted() {
    this.blendPlan = this.$route.params.blendPlan;

    this.currentPage = this.$route.params.currentPage;
    if (this.blendPlan.approvalStatus) {
      let _actions = this.$route.params.currentPage.actions_allowed;

      _actions = _actions.filter((x) => x !== "APPROVE");

      this.currentPage["actions_allowed"] = _actions;
    }
    this.companyWarehouses = await CompanyWarehouseService.get();

    // Getting saved plan details if the plan has already been generated and user is trying to edit the plan
    if (this.blendPlan.planGenerationStatus !== null) {
      // Getting saved Blend plan Details
      this.blendPlanDetails = await RequisitionAPI.getBlendPlanDetail(this.blendPlan.id);
      // Checking if the saved plan has multiple warehoused selected,
      // if so filtering and adding them to the selected warehouses.
      this.checkIfTwoWarehousesIncluded();
      // Getting available stock data for the Bland plan
      await this.searchData();
      // Merging Saved blend plan with Available stock to show already selected items
      this.mergeDetailsWithStock();

      this.isPlanSaved = true;
      this.onGridReady(params);
      this.onGridReady1(params);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.ag-theme-alpine {
  /* disable all borders */
  --ag-borders: none;
  /* then add back a border between rows */
  --ag-borders-row: dashed 1px;
  --ag-row-border-color: rgb(150, 150, 200);
  --ag-value-change-value-highlight-background-color: pink;
}
.v-card--link:focus::before {
  opacity: 0;
}
.qtyText {
  width: 65pt;
}
.v-text-field:hover {
  background-color: white;
}
.itemLabel {
  font-weight: 600;
}
.tittle__Row {
  margin-bottom: 1;
}
.label__value {
  font-weight: 600;
}

.v-data-table-header th {
  white-space: nowrap;
}
</style>
