export const TABLE_HEADERS = [
  // {
  //   text: "GP Date",
  //   value: "gpDate",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 100,
  //   sortable: true,
  // },
  {
    text: "Item Name",
    value: "itemName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 120,
    sortable: true,
  },
  {
    text: "Mark",
    value: "markName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Grade",
    value: "gradeName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Inv No",
    value: "invNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Rate",
    value: "purchasedRate",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  // {
  //   text: "Purchase Type",
  //   value: "purchaseType",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Net Kg",
    value: "netKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Density",
    value: "density",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  // {
  //   text: "Stock Type",
  //   value: "inventoryId",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Stock Quantity",
    value: "totalNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Allocated Quantity",
    value: "allocatedNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  // {
  //   text: "Warehouse",
  //   value: "companyWarehouseName",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Bay",
    value: "bay",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Required Quantity",
    value: "qtyAction",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Selected No of Packages",
    value: "requiredPackages",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Batch No",
    value: "batchNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
];
export const TABLE_HEADERS_AG = [
  // {
  //   text: "GP Date",
  //   value: "gpDate",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 100,
  //   sortable: true,
  // },
  {
    headerName: "Item Name",
    field: "itemName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 120,
    sortable: true,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: "Mark",
    field: "markName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    headerName: "Grade",
    field: "gradeName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Inv No",
    field: "invNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Rate",
    field: "purchasedRate",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },

  {
    headerName: "Net Kg",
    field: "netKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Density",
    field: "density",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },

  {
    headerName: "Stock Quantity",
    field: "totalNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    headerName: "Allocated Quantity",
    field: "allocatedNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  // {
  //   headerName: "Warehouse",
  //   field: "companyWarehouseName",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    headerName: "Bay",
    field: "bay",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
];
export const TABLE_HEADERS_AG2 = [
  // {
  //   text: "GP Date",
  //   value: "gpDate",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 100,
  //   sortable: true,
  // },
  {
    headerName: "Item Name",
    field: "itemName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 120,
    sortable: true,
  },
  {
    headerName: "Mark",
    field: "markName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    headerName: "Grade",
    field: "gradeName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Inv No",
    field: "invNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Rate",
    field: "purchasedRate",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },

  {
    headerName: "Net Kg",
    field: "netKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    headerName: "Bags",
    field: "noOfPackages",
    width: 100,
  },
  {
    headerName: "Density",
    field: "density",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },

  {
    headerName: "Stock Quantity",
    field: "totalNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    headerName: "Allocated Quantity",
    field: "allocatedNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  // {
  //   headerName: "Warehouse",
  //   field: "companyWarehouseName",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    headerName: "Bay",
    field: "bay",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
];
